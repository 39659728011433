import React from "react"
import { Link, useLocation } from "react-router-dom"

import s from "../assets/scss/footer.module.scss"

//MEDIA
import arrowBlue from "../assets/icon/arrow-blue.svg"
import arrowGray from "../assets/icon/arrow-gray.svg"

//HOOKS
import { useTranslation } from "react-i18next"
import useWindowSize from "../hooks/useWindowSize"
import LanguageDropdown from "./languageDropdown"
// import useHeaderStore from "../stores/themeStore";

export default function Footer() {
  // const isDarkTheme = useHeaderStore((state) => state.isDark);
  const size = useWindowSize()
  const location = useLocation()
  const { t, i18n } = useTranslation()

  return (
    <footer className={s.footer}>
      <div className={s.top}>
        <div className={s.links}>
          <div className={s.left}>
            <div className={s.linkWrapper}>
              {size.width <= 1024 && (
                <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
              )}
              <Link className={s.link} to={t("routes.contact")}>
                {t("footer.link.contact")}
              </Link>
            </div>

            <div className={s.linkWrapper} style={{ pointerEvents: "none", opacity: "0.3" }}>
              {size.width <= 1024 && (
                <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Blue" />
              )}
              <a className={s.link} href="">
                {t("footer.link.career")}
              </a>
            </div>
          </div>
          <div className={s.right}>
            <div className={s.linkWrapper}>
              <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
              <a className={s.link} target="_blank" rel="noreferrer" href="/pdf/surekli-bilgilendirme-formu.pdf">
                {t("footer.link.infoForm")}
              </a>
            </div>

            <div className={s.linkWrapper}>
              <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
              <a className={s.link} target="_blank" rel="noreferrer" href="/pdf/acil-ve-beklenmedik-durum-plani.pdf">
                {t("footer.link.emergencyPlan")}
              </a>
            </div>

            <div className={s.linkWrapper} style={{ pointerEvents: "none", opacity: "0.3" }}>
              <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
              <a className={s.link} href="">
                {t("footer.link.mkkPortal")}
              </a>
            </div>

            <div className={s.linkWrapper}>
              <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
              <a
                className={s.link}
                target="_blank"
                rel="noreferrer noopener"
                href="https://www.kap.org.tr/tr/sirket-bilgileri/ozet/5808-wam-gayrimenkul-ve-girisim-sermayesi-portfoy-yonetimi-a-s"
              >
                {t("footer.link.kap")}
              </a>
            </div>

            {i18n.language === "TR" && (
              <div className={s.linkWrapper} style={{ pointerEvents: "none", opacity: "0.3" }}>
                <img className={s.arrow} src={size.width >= 640 ? arrowBlue : arrowGray} alt="Arrow" />
                <a className={s.link} href="">
                  {t("footer.link.informationSociety")}
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={s.privacy}>
        <small>{t("userAgreement.text")}</small>
      </div>
      <div className={s.copyright}>
        <div className={s.left}>
          <small>
            Copyright © 2025 WAM Asset & Portfolio Management <br />
            <span className={s.seperator} style={{ padding: "0 0.5rem" }}>
              |
            </span>{" "}
            {t("footer.copyright.rights")}
          </small>
          <small>
            <Link to={t("routes.kvkk")} state={{ background: location }}>
              {t("footer.copyright.kvkk")}
            </Link>
          </small>
          <small>
            <Link to={t("routes.cookiePolicy")} state={{ background: location }}>
              {t("footer.copyright.cookiePolicy")}
            </Link>
          </small>
        </div>
        <div className={s.right}>
          <small>
            <a href="https://justdesignfx.com" target="_blank" rel="noreferrer">
              Made by JUST DESIGN FX
            </a>
          </small>
        </div>
        <div className={s.selectWrapper}>
          <LanguageDropdown color="#003566" />
        </div>
      </div>
    </footer>
  )
}
