import React, { useEffect, useLayoutEffect, useRef } from "react"
import s from "../assets/scss/executives.module.scss"

import arrow from "../assets/icon/arrow-large.svg"
import canIkinci from "../assets/img/can-ikinci.jpg"

import ExecutivePanel from "../components/executivePanel"
import useExecPanelStore from "../stores/executivePanelStore"
import useHeaderStore from "../stores/themeStore"

import { useTranslation } from "react-i18next"

import { default as gsap, default as ScrollTrigger } from "gsap"
import { useNavigate } from "react-router-dom"
gsap.registerPlugin(ScrollTrigger)

export default function Executives() {
  const executivesRef = useRef(null)

  const setHeaderDark = useHeaderStore((state) => state.setDarkTheme)

  const togglePanel = useExecPanelStore((state) => state.toggle)
  const setExec = useExecPanelStore((state) => state.setCurrentExec)
  const currentExec = useExecPanelStore((state) => state.currentExec)

  const { t, i18n } = useTranslation()

  let navigate = useNavigate()
  useEffect(() => {
    if (i18n.language === "TR") {
      navigate("/yoneticiler")
    } else {
      navigate("/executives")
    }
  }, [i18n.language])

  const items = [
    {
      name: t("executiveInfo.e1.name"),
      role: t("executiveInfo.e1.role"),
      imgSrc: canIkinci,
    },
    // {
    //   name: t("executiveInfo.e2.name"),
    //   role: t("executiveInfo.e2.role"),
    //   imgSrc: turkerTekten,
    // },
  ]

  useLayoutEffect(() => {
    setHeaderDark()
  }, [])

  const handleSelect = (selected) => {
    setExec(selected)
    togglePanel()
  }

  return (
    <main className={s.executivesWrapper} ref={executivesRef}>
      <section className={s.executives}>
        <div className={s.textBox}>
          <h3 className={s.title}>{t("executives.title")}</h3>
          <p className={s.text}>{t("executives.text")}</p>
        </div>
        <div className={s.cards}>
          {items.map((item, i) => {
            return (
              <div className={s.executiveCard} onClick={() => handleSelect(i)} key={i}>
                <div className={s.imgWrapper}>
                  <img className={s.img} src={item.imgSrc} alt="Executive Visual" />
                </div>
                <div className={s.info}>
                  <h5 className={s.title}>{item.name}</h5>
                  <small className={s.role}>{item.role}</small>
                  <div className={s.arrow}>
                    <img className={s.icon} src={arrow} alt="Arrow" />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </section>
      <ExecutivePanel currentExec={currentExec}></ExecutivePanel>
    </main>
  )
}
